@import "../node_modules/voxloud-ui-toolkit/dist/scss/components/variables.scss";
@import "../node_modules/voxloud-ui-toolkit/dist/css/style.min.css";

@import "../node_modules/flag-icon-css/css/flag-icon.css";

// Override default bootstrap font
// you can override other bootstrap scss variables below
$font-family-base: GT-Eesti-Pro, Roboto, Arial, sans-serif;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

body {
  overflow-y: hidden;
}

// Disabling body background for specific features 
body:has(.desktop-app__widget), body:has(.reviews__widget) {
  background: transparent!important;
}

vox-coverage-flow {
  vox-address-search {
    // workaround for iphones
    .vox-text-field {
      .input-field {
        padding: 0px;
      }
    }
    .address-search-dropdown-container {
      max-height: 200px;
      overflow-y: auto;
      border-color: $medium-grey;
    }
    .dropdown-item {
      background-color: $white;
      white-space: normal !important;
    }
  }
}


.typeahead-window{
  width: 100%;
}

vox-callback-content {
  div.dropdown-container.dropdown-menu {
    top: -10.3rem;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
